.preloader {
    height: 100vh;
    width: 100%;
    background: #000;
    color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 55;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden !important;
}

.preloader .texts-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    width: 700px;
    font-size: 28px;
    overflow: hidden;
    font-weight: 800;
    opacity: 0;
    color: #fff;
}
@media only screen and  (min-width:320px) and (max-width:991px) {
    .preloader .texts-container {
        height: 60px;
        width: 400px;
        font-size: 18px;
        /* overflow: hidden; */
        font-weight: 800;
        opacity: 0;
        color: #fff;
    }
}